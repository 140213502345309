<template>
  <v-card
    @click="goToProfile"
    class="rounded-lg"
    :style="onlyFollowed && !getFanned ? 'display: none' : ''"
  >
    <div class="d-flex align-center justify-start h-100 pa-5 d-flex flex-row">
      <agency-avatar
        :img="agency.midThumbnail ? agency.midThumbnail : agency.image"
        :name="agency.name"
        :size="60"
      />
      <span
        class="primary-color font-12 app-bold-font text-left text-ellipsis break-word line-height-15 force-height-30 align-center ml-4"
      >
        {{ agency.name }}
      </span>
    </div>
    <v-btn
      block
      style="max-height: 40px; height: 40px;"
      :color="getFanned ? '#A1CC33' : '#0A5B8A'"
      dark
      class="rounded-b-lg"
      @click.stop="becameFan"
      :loading="loading"
    >
      {{ getFanned ? "Unfollow" : "Follow" }}
    </v-btn>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AgencyAvatar from "@/components/agency/AgencyAvatar.vue";
export default {
  components: { AgencyAvatar },
  props: {
    agency: Object
  },
  data() {
    return {
      loading: false,
      onlyFollowed: false
    };
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      type: "type"
    }),
    getCompletedInfo() {
      var str = "";
      if (!this.agency.image) str += "Logo";
      if (!this.agency.who_we_are)
        str ? (str += ", Who we are") : (str = "Who we are");
      if (!this.agency.what_we_do)
        str ? (str += ", What we do") : (str = "What we do");
      if (!this.agency.contact_person || !this.agency.contact_title)
        str ? (str += ", Contact Info") : (str = "Contact Info");
      if (!this.agency.phone) str ? (str += ", Phone") : (str = "Phone");
      if (!this.agency.address) str ? (str += ", Address") : (str = "Address");
      if (!this.agency.city) str ? (str += ", City") : (str = "City");
      if (!this.agency.state) str ? (str += ", State") : (str = "State");
      if (!this.agency.zip) str ? (str += ", Zip") : (str = "Zip");

      return str;
    },
    getFanned() {
      if (!this.profile.fanned) return false;
      if (this.profile.type == "Agency") {
        if (this.profile.followers.includes(this.agency._id)) return true;
      }
      if (this.profile.fanned.includes(this.agency._id)) return true;
      return false;
    }
  },
  methods: {
    ...mapActions("volunteer", {
      followAgency: "followAgency"
    }),
    goToProfile() {
      this.$router.replace({
        name: "donation-set-amount",
        query: { id: this.agency._id }
      });
    },
    becameFan() {
      this.loading = true;
      var fanned = this.getFanned;
      this.followAgency({ fanned: !fanned, agency: this.agency._id })
        .then(() => {
          this.loading = false;
          // console.log(res);
        })
        .catch(error => {
          this.loading = false;
          console.log(error.response.data.message);
        });
    }
  },
  watch: {
    "$route.query.agency": function(value) {
      if (value == "followed") {
        this.onlyFollowed = true;
      } else {
        this.onlyFollowed = false;
      }
    }
  },
  mounted() {
    if (this.$route.query.agency == "followed") {
      this.onlyFollowed = true;
    } else {
      this.onlyFollowed = false;
    }
  }
};
</script>
<style></style>
